.privacy li {
  font-size: 24px;
  margin-bottom: 40px 0;
  font-weight: 600;
  color: #fff;
}
.privacy p {
  font-size: 22px !important;
  font-weight: 400;
  margin: 5px 0;
  color: rgba(255, 255, 255, 0.7);
}

.privacy li ul {
  margin-left: 50px;
}
.privacy li blockquote {
  padding-left: 2em;
  border-left: 2px solid;
  margin: 30px 0;
  font-size: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 768px) {
  .privacy li {
    font-size: 20px;
  }
  .privacy p {
    font-size: 18px !important;
  }
  .privacy li blockquote {
    font-size: 18px;
  }
}
