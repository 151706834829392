/* nav-bar */
.navBar {
  background: transparent !important;
  /* make the background blur  */
  backdrop-filter: blur(30px);
  z-index: 99999 !important;
}
.navLink {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px !important;
  cursor: pointer;
  font-weight: 400 !important;
  position: relative;
}
.navLinkActive {
  color: #fff;
  font-size: 18px !important;
  cursor: pointer;
  font-weight: 600 !important;
  position: relative;
}

.subMenuBox {
  opacity: 0;
  visibility: hidden;
  z-index: 99999999999999999;
  position: absolute;
  top: 100px;
  left: 0;
  width: 200px;
  background-color: #25262c;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.1);
  padding: 10px;
  transition: all 0.8s ease-in-out;
}
.navLink:hover .subMenuBox,
.navLinkActive:hover .subMenuBox {
  opacity: 1;
  visibility: visible;
  top: 50px;
}

.subMenu {
  display: flex;
  flex-direction: column;
}
.subMenu a {
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #838282;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}
.subMenu a:last-child {
  border-bottom: none;
}
.subMenu a:hover {
  color: #fff;
  padding-left: 20px;
}

.mobileSubMenu {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #838282;
  padding: 2px;
  transition: all 0.3s ease-in-out;
}
.mobileSubMenu:last-child {
  border-bottom: none;
}
.mobileSubMenu:hover {
  color: #fff;
  padding-left: 10px;
}
.logo {
  cursor: pointer !important;
  margin-top: 10px;
}
.logo img {
  object-fit: cover;
  cursor: pointer !important;
}
.homeIcon {
  width: 24px;
  cursor: pointer;
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  .headerShape {
    display: none;
  }

  .navLink,
  .navLinkActive {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 480px) {
  .logo {
    width: 100px;
  }
}
