/* hero  */

.heroSection {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.heroSection video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.heroContent {
  position: relative;
  z-index: 99;
  color: #d9d9d9;
  text-align: left;
  padding: 20px;
}

.heroSection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

/* map */

.map {
  width: 100%;
  object-fit: cover;
}

.americasMap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 39.5%;
  cursor: pointer;
}
.europeMap {
  position: absolute;
  top: 0;
  left: 40%;
  height: 100%;
  width: 19.5%;
  cursor: pointer;
}
.asiaMap {
  position: absolute;
  top: 0;
  left: 60%;
  height: 100%;
  width: 39.5%;
  cursor: pointer;
}

.americasMap:hover,
.europeMap:hover,
.asiaMap:hover {
  background: linear-gradient(
    180deg,
    rgba(255, 101, 1, 0) 0%,
    rgba(255, 101, 1, 0.2) 22.94%,
    rgba(255, 101, 1, 0.2) 80.58%,
    rgba(255, 101, 1, 0) 100%
  );
}

.selectedArea {
  background: linear-gradient(
    180deg,
    rgba(255, 101, 1, 0) 0%,
    rgba(255, 101, 1, 0.2) 22.94%,
    rgba(255, 101, 1, 0.2) 80.58%,
    rgba(255, 101, 1, 0) 100%
  );
}

/* partners */
/* the slides */

/* 
.serviceSection {
  background: url(../assets/images/services-bg.png) no-repeat center center /
    cover;
}
.proServiceSection {
  background: url(../assets/images/production-services-bg.png) no-repeat center
    center / cover;
  position: relative;
  z-index: 1;
}
.proServiceSection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.ottSection {
  background: url(../assets/images/ott-bg.svg) no-repeat center center / cover;
} */

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .heroSection {
    justify-content: center;
    min-height: 100vh;
    padding-top: 60px;
  }

  .heroSection video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: none;
    max-height: none;
  }

  .heroContent {
    padding: 40px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .heroSection {
    justify-content: flex-start;
    padding-top: 60px;
  }

  .heroContent {
    padding-top: 20px;
  }
  .ios,
  .android,
  .kaios {
    width: 80%;
    margin: auto;
  }
}
