/* reset style */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  object-fit: cover;
}

/* google font  */
@import url("https://fonts.googleapis.com/css2?family=Signika:wght@400;600&display=swap");

body {
  font-family: "Signika", sans-serif;
  font-weight: 400 !important;
  background: #25262c;
  color: #fff;
  line-height: 34px;
}
a {
  text-decoration: none;
  color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

/* sectionTitle */
.sectionTitle {
  position: relative;
  text-transform: uppercase;
}
.sectionTitle::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 30px;
  height: 4px;
  /* background: #ec7913; */
  background: #ff6600;
}
.sectionTitle::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 40px;
  width: 30px;
  height: 4px;
  background: #007eee;
}

.storeLogo {
  width: 120px;
  border: 1px solid #fff;
  border-radius: 5px;
}

/* auto fill color */
input:-webkit-autofill,
.sivooForm input:-webkit-autofill:hover,
.sivooForm input:-webkit-autofill:focus,
.sivooForm input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px #363841 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.partners .slick-slide {
  padding: 20px;
  margin: 10px;
}

.sivooAddressMap {
  filter: grayscale(100%) invert(90%);
}

@media only screen and (max-width: 992px) {
  body {
    line-height: 28px;
  }
}
@media only screen and (max-width: 768px) {
  body {
    line-height: 24px;
  }
  .partners .slick-slide {
    padding: 0px;
    margin: 5px;
  }
  .storeLogo {
    width: 80px;
  }
}
